import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Table,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import gig from "../../assets/images/loders.gif"
import { URLS } from "../../Url"
import axios from "axios"

function DigitalBrochure() {
  const [isLoading, setIsLoading] = useState(true)

  const [files, setFiles] = useState([])

  const [files1, setFiles1] = useState([])

  const [files3, setFiles3] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG" ||
      type == "JPEG" ||
      type == "WEBP" ||
      type == "webp"
    ) {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG" ||
      type == "JPEG" ||
      type == "WEBP" ||
      type == "webp"
    ) {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler3 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG" ||
      type == "JPEG" ||
      type == "WEBP" ||
      type == "webp"
    ) {
      setFiles3(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const [form, setform] = useState([])

  const [forms, setforms] = useState([])

  const handlechange = e => {
    const myform = { ...forms }
    myform[e.target.name] = e.target.value
    setforms(myform)
  }

  useEffect(() => {
    GetAllBroucher()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetAllBroucher = () => {
    var token = datas

    axios
      .post(
        URLS.GetContactUs,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.contactus)
        setforms(res.data.contactus)
        setIsLoading(false)
      })
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup1 = () => {
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()

    changstatus()
  }

  const changstatus = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", forms.title)
    dataArray.append("addresss", forms.addresss)
    dataArray.append("phone", forms.phone)
    dataArray.append("email", forms.email)
    dataArray.append("map", forms.map)
    dataArray.append("facebook", forms.facebook)
    dataArray.append("twitter", forms.twitter)
    dataArray.append("instagram", forms.instagram)
    dataArray.append("fromTime", forms.fromTime)
    dataArray.append("toTime", forms.toTime)
    dataArray.append("whatsapp", forms.whatsapp)
    dataArray.append("footerDescription", forms.footerDescription)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i])
    }

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("bannerImage", files1[i])
    }

    for (let i = 0; i < files3.length; i++) {
      dataArray.append("logo", files3[i])
    }

    axios
      .put(URLS.UpdateContactUs, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetAllBroucher()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
            setIsLoading(false)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Fortune Immigration"
            breadcrumbItem="Contact Us"
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white"></CardHeader>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div style={{ float: "right" }}>
                        <Button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit Booking"
                          onClick={() => {
                            getpopup1(form)
                          }}
                          className="mr-5 mb-1 m-1 "
                          color="success"
                          outline
                        >
                          <i className="bx bx-edit text-dark "></i>
                          <span>Edit</span>
                        </Button>
                      </div>

                      <Row>
                        <div className="m-5">
                          <div className="table-rep-plugin mt-4 table-responsive">
                            <Table hover className="table table-bordered mb-4">
                              <thead>
                                <tr className="text-center">
                                  <th>Banner Image</th>
                                  <td>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.bannerImage}
                                      height="80px"
                                      width="260px"
                                    ></img>
                                  </td>
                                </tr>

                                <tr className="text-center">
                                  <th> Image</th>
                                  <td>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.image}
                                      height="80px"
                                      width="100px"
                                    ></img>
                                  </td>
                                </tr>

                                <tr className="text-center">
                                  <th> Logo</th>
                                  <td>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.logo}
                                      height="60px"
                                      width="150px"
                                    ></img>
                                  </td>
                                </tr>

                                <tr className="text-center">
                                  <th>Tiltle </th>
                                  <td>{form.title}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Address</th>
                                  <td>{form.addresss}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Phone </th>
                                  <td>{form.phone}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Email</th>
                                  <td>{form.email}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Map </th>
                                  <td>{form.map.slice(0, 100)}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Facebook </th>
                                  <td>{form.facebook}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Whatsapp </th>
                                  <td>{form.whatsapp}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Twitter </th>
                                  <td>{form.twitter}</td>
                                </tr>
                                <tr className="text-center">
                                  <th>Instagram </th>
                                  <td>{form.instagram}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>From Time </th>
                                  <td>{form.fromTime}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>To Time </th>
                                  <td>{form.toTime}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Footer Description </th>
                                  <td>{form.footerDescription}</td>
                                </tr>
                              </thead>
                            </Table>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>

          <Modal
            size="xl"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Contact Us{" "}
              </h5>{" "}
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  submibooking(e)
                }}
              >
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Image <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        name="image"
                        value={files.image}
                        onChange={changeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Banner Image{" "}
                        <span className="text-danger">* 1920 x 850</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        name="image"
                        value={files1.image}
                        onChange={changeHandler1}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Logo <span className="text-danger">*246 x 52</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        name="image"
                        value={files3.image}
                        onChange={changeHandler3}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Title"
                        required
                        value={forms.title}
                        name="title"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Description"
                        required
                        value={forms.addresss}
                        name="addresss"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Footer Description
                        <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Footer Description"
                        required
                        value={forms.footerDescription}
                        name="footerDescription"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>

                </Row>

                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Phone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Phone "
                        required
                        value={forms.phone}
                        name="phone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Email"
                        required
                        value={forms.email}
                        name="email"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Map Url
                        <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Map Url"
                        required
                        value={forms.map}
                        name="map"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>

                 
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Whatsapp Number <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Whatsapp Number"
                        required
                        value={forms.whatsapp}
                        name="whatsapp"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Facebook Link <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Facebook Link"
                        required
                        value={forms.facebook}
                        name="facebook"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Twitter Link <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Twitter Link"
                        required
                        value={forms.twitter}
                        name="twitter"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Instagram Link <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Instagram Link"
                        required
                        value={forms.instagram}
                        name="instagram"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        From Time <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="time"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter From Time"
                        required
                        value={forms.fromTime}
                        name="fromTime"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        To Time <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="time"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter To Time"
                        required
                        value={forms.toTime}
                        name="toTime"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <hr></hr>
                <div style={{ float: "right" }} className="m-2">
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default DigitalBrochure
