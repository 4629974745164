import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Otp from "pages/Authentication/Otp"
import Setpwd from "pages/Authentication/Setpwd"

//dashboard
// import Dashboard from "../pages/Dashboard/index"

//Users
import UsersList from "pages/Mcch/Users/UsersList"

//Banners
import Banners from "pages/Mcch/Banners"

//Settings
import About from "pages/Mcch/Settings/About"
import PrivacyPolicy from "pages/Mcch/Settings/PrivacyPolicy"
import TermsandConditions from "pages/Mcch/Settings/TermsandConditions"
import RefundPolicy from "pages/Mcch/Settings/RefundPolicy"
import Languages from "pages/Mcch/Settings/Languages"
import Faqs from "pages/Mcch/Settings/Faqs"
import Testimoni from "pages/Mcch/Testimoni"
import Team from "pages/Mcch/Team"

import Study from "pages/Mcch/Study"
import Migrate from "pages/Mcch/Migrate"
import Visit from "pages/Mcch/Visit"

import AboutUs from "pages/Mcch/AboutUs"
import ContactUs from "pages/Mcch/ContactUs"
import GetCallBack from "pages/Mcch/Users/GetCallBack"

const authProtectedRoutes = [
  // { path: "/dashboard", component: Dashboard },

  { path: "/GetCallBack", component: GetCallBack },

  //Team
  { path: "/Team", component: Team },
  { path: "/AboutUs", component: AboutUs },

  //Testimoni
  { path: "/Testimoni", component: Testimoni },

  //Category
  { path: "/Study", component: Study },
  { path: "/Migrate", component: Migrate },
  { path: "/Visit", component: Visit },

  //Users
  { path: "/UsersList", component: UsersList },

  //Banners
  { path: "/dashboard", component: Banners },

  //Settings
  { path: "/About", component: About },
  { path: "/PrivacyPolicy", component: PrivacyPolicy },
  { path: "/TermsandConditions", component: TermsandConditions },
  { path: "/Languages", component: Languages },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Faqs", component: Faqs },
  { path: "/ContactUs", component: ContactUs },

  //Settings
  { path: "/profile", component: UserProfile },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/setpassword", component: Setpwd },
]

export { publicRoutes, authProtectedRoutes }
