const Url = "https://api.thefortuneimmigrations.com/"

export const URLS = {
  Base: Url,

  //Profile
  getProfile: Url + "v1/fortuneImmigartionsapi/admin/auth/getadminprofile",
  updateprofile:
    Url + "v1/fortuneImmigartionsapi/admin/auth/updateadminprofile",
  Changepass: Url + "v1/fortuneImmigartionsapi/admin/auth/changepass",
  UpdateImages: Url + "v1/fortuneImmigartionsapi/admin/auth/editadprofileImg",

  forget: Url + "mcch/api/admin/forgotpassword",
  OTP: Url + "mcch/api/admin/compareotp",
  Resetpass: Url + "mcch/api/admin/resetpassword",

  //Banners
  AddBanners: Url + "v1/fortuneImmigartionsapi/admin/slide/addslide",
  GetBanners: Url + "v1/fortuneImmigartionsapi/admin/slide/getallslides",
  GetBannersSearch:
    Url + "v1/fortuneImmigartionsapi/admin/slide/getallslides?searchQuery=",
  UpdateBanners: Url + "v1/fortuneImmigartionsapi/admin/slide/editslide/",
  InActiveBanners: Url + "v1/fortuneImmigartionsapi/admin/slide/deleteslide/",

  //Team
  AddTeam: Url + "v1/fortuneImmigartionsapi/admin/team/addteam",
  GetTeam: Url + "v1/fortuneImmigartionsapi/admin/team/getallteams",
  GetTeamSearch:
    Url + "v1/fortuneImmigartionsapi/admin/team/getallteams?searchQuery=",
  UpdateTeam: Url + "v1/fortuneImmigartionsapi/admin/team/editteam/",
  InActiveTeam: Url + "v1/fortuneImmigartionsapi/admin/team/deleteteam/",

  //Testimoni
  AddTestimoni: Url + "v1/fortuneImmigartionsapi/admin/testimoni/addtestimoni",
  GetTestimoni: Url + "v1/fortuneImmigartionsapi/admin/testimoni/getAll",
  GetTestimoniSearch:
    Url + "v1/fortuneImmigartionsapi/admin/testimoni/getAll?searchQuery=",
  UpdateTestimoni:
    Url + "v1/fortuneImmigartionsapi/admin/testimoni/edittestimoni/",
  InActiveTestimoni:
    Url + "v1/fortuneImmigartionsapi/admin/testimoni/deletetestimoni/",

  //STUDY
  AddStudy: Url + "v1/fortuneImmigartionsapi/admin/study/addstudy",
  GetStudy: Url + "v1/fortuneImmigartionsapi/admin/study/getAll",
  GetStudySearch:
    Url + "v1/fortuneImmigartionsapi/admin/study/getAll?searchQuery=",
  UpdateStudy: Url + "v1/fortuneImmigartionsapi/admin/study/editstudy/",
  InActiveStudy: Url + "v1/fortuneImmigartionsapi/admin/study/deletestudy/",

  //Migrate
  AddMigrate: Url + "v1/fortuneImmigartionsapi/admin/migrate/addmigrate",
  GetMigrate: Url + "v1/fortuneImmigartionsapi/admin/migrate/getAllmigrates",
  GetMigrateSearch:
    Url + "v1/fortuneImmigartionsapi/admin/migrate/getAllmigrates?searchQuery=",
  UpdateMigrate: Url + "v1/fortuneImmigartionsapi/admin/migrate/editmigrate/",
  InActiveMigrate:
    Url + "v1/fortuneImmigartionsapi/admin/migrate/deletemigrate/",

  //Visit
  AddVisit: Url + "v1/fortuneImmigartionsapi/admin/visit/addvisit",
  GeVisit: Url + "v1/fortuneImmigartionsapi/admin/visit/getAllvisits",
  GetVisitSearch:
    Url + "v1/fortuneImmigartionsapi/admin/visit/getAllvisits?searchQuery=",
  UpdateVisit: Url + "v1/fortuneImmigartionsapi/admin/visit/editvisit/",
  InActiveVisit: Url + "v1/fortuneImmigartionsapi/admin/visit/deletevisit/",

  //CONTACT US
  GetContactUs: Url + "v1/fortuneImmigartionsapi/admin/contactus/getcontactus",
  UpdateContactUs:
    Url + "v1/fortuneImmigartionsapi/admin/contactus/editcontactus/",

  //About Us
  GetAboutUs: Url + "v1/fortuneImmigartionsapi/admin/aboutus/getaboutus",
  UpdateAboutUs: Url + "v1/fortuneImmigartionsapi/admin/aboutus/editaboutus/",

  //Faqs
  AddFaqs: Url + "v1/fortuneImmigartionsapi/admin/faq/addfaq",
  GetFaqs: Url + "v1/fortuneImmigartionsapi/admin/faq/getAll",
  GetFaqsSearch: Url + "v1/fortuneImmigartionsapi/admin/faq/getAll?searchQuery=",
  UpdateFaqs: Url + "v1/fortuneImmigartionsapi/admin/faq/editfaq",
  InActiveFaqs: Url + "v1/fortuneImmigartionsapi/admin/faq/deletefaq",
  GetIdFaqs: Url + "v1/fortuneImmigartionsapi/admin/faq/getalltypefaqs",

  //Users
  GetUser: Url + "v1/fortuneImmigartionsapi/admin/getallenquiry",
  GetUserSearch:
    Url + "v1/fortuneImmigartionsapi/admin/getallenquiry?searchQuery=",
  deleteuser: Url + "v1/fortuneImmigartionsapi/admin/deleteenquiry/",

  //CallBack
  GetCallBack: Url + "v1/fortuneImmigartionsapi/admin/getallrequests",
  deleteCallBack: Url + "v1/fortuneImmigartionsapi/admin/deleterequest/",

  //Settings 
  GetSetting: Url + "v1/fortuneImmigartionsapi/admin/setting/getSetting",
  UpdateTerms: Url + "v1/fortuneImmigartionsapi/admin/setting/updatetermsandcondition",
}
