import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const Staff = () => {
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [show2, setshow2] = useState(false)

  const [text1, setText1] = useState([])
  const [text, setText] = useState([])

  const [files, setFiles] = useState([])
  const [files1, setFiles1] = useState([])

  const [files3, setFiles3] = useState([])
  const [files4, setFiles4] = useState([])

  const [inputList, setInputList] = useState([{ title: "", description: "" }])

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { title: "", description: "" }])
  }

  const [inputList1, setInputList1] = useState([])

  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList1]
    list[index][name] = value
    setInputList1(list)
  }

  const handleRemoveClick1 = index => {
    const list = [...inputList1]
    list.splice(index, 1)
    setInputList1(list)
  }

  const handleAddClick1 = () => {
    setInputList1([...inputList1, { title: "", description: "" }])
  }

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "JPG" || type == "JPEG" || type == "PNG" || type == "JPEG"|| type == "WEBP"|| type == "webp") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "JPG" || type == "JPEG" || type == "PNG" || type == "JPEG"|| type == "WEBP"|| type == "webp") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler3 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "JPG" || type == "JPEG" || type == "PNG" || type == "JPEG"|| type == "WEBP"|| type == "webp") {
      setFiles3(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler4 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "JPG" || type == "JPEG" || type == "PNG" || type == "JPEG"|| type == "WEBP"|| type == "webp") {
      setFiles4(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const [form, setform] = useState([])
  const [users, setusers] = useState([])
  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        URLS.GeVisit,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.visits)
      })
  }

  const custsearch = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const token = datas
    console.log(token)
    axios
      .post(
        URLS.GetVisitSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setusers(res.data.visits)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Add = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("title", form.title)

    dataArray.append("description", form.description)

    dataArray.append("largeDescription", text)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i])
    }

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("bannerImage", files1[i])
    }

    dataArray.append("otherDescription", JSON.stringify(inputList))

    axios
      .post(URLS.AddVisit, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setform("")
            setFiles({ image: "" })
            setFiles1({ image: "" })
            Get()
            setshow(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formsubmit = e => {
    e.preventDefault()
    Add()
  }

  const Update = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", form1.title)

    dataArray.append("description", form1.description)

    dataArray.append("largeDescription", text1)

    for (let i = 0; i < files3.length; i++) {
      dataArray.append("image", files3[i])
    }

    for (let i = 0; i < files4.length; i++) {
      dataArray.append("bannerImage", files4[i])
    }
    dataArray.append("otherDescription", JSON.stringify(inputList1))

    axios
      .put(URLS.UpdateVisit + form1._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            Get()
            toast(res.data.message)
            setFiles3({ image: "" })
            setFiles4({ image: "" })
            setshow2(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formeditsubmit = e => {
    e.preventDefault()
    Update()
  }

  useEffect(() => {
    Get()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = datau => {
    var token = datas
    var remid = datau._id
    axios
      .delete(URLS.InActiveVisit + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const usedata = datal => {
    // setshow1(true)
    setshow2(true)
    setform1(datal)
    setText1(datal.largeDescription)
    setInputList1(datal.otherDescription)
  }

  //view information
  const [info, setinfo] = useState(false)
  const [Information, setInformation] = useState([])

  function infos() {
    setinfo(!info)
  }

  const getinfo = data => {
    setInformation(data.largeDescription)
    infos()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Fortune Immigration" breadcrumbItem="Visit" />

          <Row>
            <Col>
              {show == true ? (
                <Card className="p-4">
                  <Form
                    onSubmit={e => {
                      formsubmit(e)
                    }}
                  >
                    <h5>Add New Study</h5>
                    <Row className="pt-3">
                      <Col md="4">
                        <Label> Title</Label>
                        <span className="text-danger">*</span>
                        <Input
                          name="title"
                          onChange={e => {
                            handleChange(e)
                          }}
                          value={form.title}
                          required
                          type="text"
                          placeholder="Enter Title"
                        />
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Test Image
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler}
                            required
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Banner Image
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Banner image"
                            name="image"
                            onChange={changeHandler1}
                            required
                          />
                        </div>
                      </Col>
                      <Col md="12" className="mt-3 mb-3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Description"
                            required
                            rows={3}
                            value={form.description}
                            name="description"
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="12">
                        <div>
                          <Row>
                            {inputList.map((x, i) => {
                              return (
                                <>
                                  <Row>
                                    <div key={i} className="box row">
                                      <Col md="5" sm="12" className="mb-3">
                                        <Label>Sub Title</Label>
                                        <Input
                                          type="text"
                                          name="title"
                                          placeholder="Enter Title"
                                          value={x.title}
                                          onChange={e =>
                                            handleInputChange(e, i)
                                          }
                                        />
                                      </Col>
                                      <Col md="5" sm="12" className="mb-3">
                                        <Label>Sub Description</Label>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          id="basicpill-firstname-input1"
                                          placeholder="Enter Description"
                                          rows={1}
                                          value={x.description}
                                          name="description"
                                          onChange={e =>
                                            handleInputChange(e, i)
                                          }
                                        />
                                      </Col>

                                      <Col sm="2" md="2" className="mt-4">
                                        <div className="btn-box">
                                          {inputList.length !== 1 && (
                                            <button
                                              className="mr10 btn btn-outline-danger btn-sm m-1"
                                              type="button"
                                              onClick={() =>
                                                handleRemoveClick(i)
                                              }
                                            >
                                              Remove
                                              <i className="bx bx-x-circle"></i>
                                            </button>
                                          )}
                                          {inputList.length - 1 === i && (
                                            <button
                                              className="btn btn-sm btn-outline-info m-1"
                                              onClick={handleAddClick}
                                            >
                                              Add
                                              <i className="bx bx-plus-circle"></i>
                                            </button>
                                          )}
                                        </div>
                                      </Col>
                                    </div>
                                  </Row>
                                </>
                              )
                            })}
                          </Row>
                        </div>
                      </Col>

                      <Col md="12" className="mt-3 mb-3">
                        <Label>Large Description</Label>
                        <CKEditor
                          editor={ClassicEditor}
                          id="header"
                          data={text}
                          onReady={editor => {
                            console.log("Editor is ready to use!", editor)
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            setText(data)
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </Card>
              ) : (
                ""
              )}
              {show2 == true ? (
                <Card className="p-4">
                  {" "}
                  <Form
                    onSubmit={e => {
                      formeditsubmit(e)
                    }}
                  >
                    <Row className="pt-3">
                      <Col md="4">
                        <Label> Title</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="title"
                          onChange={e => {
                            handleChange1(e)
                          }}
                          value={form1.title}
                          required
                          type="text"
                          placeholder="Enter Title"
                        />
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Test Image
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler3}
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Banner Image
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Banner image"
                            name="image"
                            onChange={changeHandler4}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Description"
                            required
                            rows={3}
                            value={form1.description}
                            name="description"
                            onChange={e => {
                              handleChange1(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="12">
                        <div>
                          <Row>
                            {inputList1.map((x, i) => {
                              return (
                                <>
                                  <Row>
                                    <div key={i} className="box row">
                                      <Col md="5" sm="12" className="mb-3">
                                        <Label>Sub Title</Label>
                                        <Input
                                          type="text"
                                          name="title"
                                          placeholder="Enter Title"
                                          value={x.title}
                                          onChange={e =>
                                            handleInputChange1(e, i)
                                          }
                                        />
                                      </Col>
                                      <Col md="5" sm="12" className="mb-3">
                                        <Label>Sub Description</Label>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          id="basicpill-firstname-input1"
                                          placeholder="Enter Description"
                                          rows={1}
                                          value={x.description}
                                          name="description"
                                          onChange={e =>
                                            handleInputChange1(e, i)
                                          }
                                        />
                                      </Col>

                                      <Col sm="2" md="2" className="mt-4">
                                        <div className="btn-box">
                                          {inputList1.length !== 1 && (
                                            <button
                                              className="mr10 btn btn-outline-danger btn-sm m-1"
                                              type="button"
                                              onClick={() =>
                                                handleRemoveClick1(i)
                                              }
                                            >
                                              Remove
                                              <i className="bx bx-x-circle"></i>
                                            </button>
                                          )}
                                          {inputList1.length - 1 === i && (
                                            <button
                                              className="btn btn-sm btn-outline-info m-1"
                                              onClick={handleAddClick1}
                                            >
                                              Add
                                              <i className="bx bx-plus-circle"></i>
                                            </button>
                                          )}
                                        </div>
                                      </Col>
                                    </div>
                                  </Row>
                                </>
                              )
                            })}
                          </Row>
                        </div>
                      </Col>
                      <Col md="12">
                        <CKEditor
                          editor={ClassicEditor}
                          id="header"
                          data={text1}
                          onReady={editor => {
                            console.log("Editor is ready to use!", editor)
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            setText1(data)
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="text-end mt-3">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow2(false)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </Card>
              ) : (
                ""
              )}
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Button
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="primary"
                      >
                        New Visit <i className="bx bx-user-plus"></i>
                      </Button>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form.search}
                          onChange={custsearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Image</th>
                          <th>Banner Image</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Large Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">
                              {(pageNumber - 1) * 5 + key + 6}
                            </th>
                            <td>
                              <img
                                src={URLS.Base + data.image}
                                height="60px"
                                width="80px"
                              ></img>
                            </td>
                            <td>
                              <img
                                src={URLS.Base + data.bannerImage}
                                height="60px"
                                width="250px"
                              ></img>
                            </td>
                            <td>{data.title}</td>
                            <td>{data.description}</td>
                            <td>
                              <Button
                                size="sm"
                                className="m-1"
                                outline
                                color="warning"
                                onClick={() => {
                                  getinfo(data)
                                }}
                              >
                                <i className="bx bxs-file"></i> View
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  usedata(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                              </Button>

                              <Button
                                size="sm"
                                className="m-1"
                                outline
                                color="danger"
                                onClick={() => {
                                  manageDelete(data)
                                }}
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-trash"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
      <Modal size="lg" isOpen={show1} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Edit Visit </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              formeditsubmit(e)
            }}
          >
            <Row className="pt-3">
              <Col md="4">
                <Label> Title</Label> <span className="text-danger">*</span>
                <Input
                  name="title"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.title}
                  required
                  type="text"
                  placeholder="Enter Title"
                />
              </Col>
              <Col lg="4">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Test Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler3}
                  />
                </div>
              </Col>
              <Col lg="4">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Banner Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Banner image"
                    name="image"
                    onChange={changeHandler4}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description"
                    required
                    rows={3}
                    value={form1.description}
                    name="description"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
              </Col>{" "}
              <Col md="12">
                <CKEditor
                  editor={ClassicEditor}
                  id="header"
                  data={text1}
                  onReady={editor => {
                    console.log("Editor is ready to use!", editor)
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setText1(data)
                  }}
                />
              </Col>
            </Row>
            <div className="text-end mt-3">
              <Button type="submit" color="success m-1" outline>
                Submit <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setshow1(!show1)
                }}
                color="danger m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        isOpen={info}
        toggle={() => {
          infos()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            View Large Description :-
          </h5>
          <button
            onClick={() => {
              setinfo(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {" "}
          <div
            dangerouslySetInnerHTML={{
              __html: Information,
            }}
          ></div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Staff
